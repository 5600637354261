var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card card-custom" }, [
    _c("div", { staticClass: "card-body p-0" }, [
      _c(
        "div",
        {
          staticClass: "wizard wizard-1",
          attrs: {
            id: "kt_wizard_v1",
            "data-wizard-state": "step-first",
            "data-wizard-clickable": "false"
          }
        },
        [
          _c("div", { staticClass: "wizard-nav border-bottom" }, [
            _c("div", { staticClass: "wizard-steps p-8 p-lg-10" }, [
              _c(
                "div",
                {
                  staticClass: "wizard-step",
                  attrs: { "data-wizard-type": "step" }
                },
                [
                  _c("div", { class: "wizard-label row" }, [
                    _c("i", { staticClass: "wizard-icon flaticon-book col" }),
                    _c("h3", { staticClass: "wizard-title col" }, [
                      _vm._v("Información General")
                    ])
                  ]),
                  _c("i", { staticClass: "wizard-arrow flaticon2-next" })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "wizard-step",
                  attrs: { "data-wizard-type": "step" }
                },
                [
                  _c("div", { class: "wizard-label row" }, [
                    _c("i", { staticClass: "wizard-icon flaticon-file col" }),
                    _c("h3", { staticClass: "wizard-title col" }, [
                      _vm._v("Integración de Servicio")
                    ])
                  ]),
                  _c("i", { staticClass: "wizard-arrow flaticon2-next" })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "wizard-step",
                  attrs: { "data-wizard-type": "step" }
                },
                [
                  _c("div", { class: "wizard-label row" }, [
                    _c("i", { staticClass: "wizard-icon flaticon-notes col" }),
                    _c("h3", { staticClass: "wizard-title col" }, [
                      _vm._v("Contenidos y Documentación")
                    ])
                  ]),
                  _c("i", { staticClass: "wizard-arrow flaticon2-next" })
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "wizard-step",
                  attrs: { "data-wizard-type": "step" }
                },
                [
                  _c("div", { class: "wizard-label row" }, [
                    _c("i", { staticClass: "wizard-icon flaticon-globe col" }),
                    _c("h3", { staticClass: "wizard-title col" }, [
                      _vm._v("Limitaciones de Acceso")
                    ])
                  ]),
                  _c("i", { staticClass: "wizard-arrow last flaticon2-next" })
                ]
              )
            ])
          ]),
          _c(
            "div",
            {
              staticClass:
                "row justify-content-center my-10 px-8 my-lg-15 px-lg-10"
            },
            [
              _c("div", { staticClass: "col-xl-12 col-xxl-11" }, [
                _c("div", { staticClass: "form", attrs: { id: "kt_form" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "pb-5",
                      attrs: { "data-wizard-type": "step-content" }
                    },
                    [
                      _c(
                        "h3",
                        { staticClass: "mb-10 font-weight-bold text-dark" },
                        [_vm._v(" Información general ")]
                      ),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Imagen del servicio")]),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "image-input image-input-outline",
                              attrs: { id: "kt_profile_avatar" }
                            },
                            [
                              _c("div", {
                                staticClass: "image-input-wrapper",
                                style: {
                                  backgroundImage: "url('" + _vm.photo + "')",
                                  backgroundPosition: "center center"
                                }
                              }),
                              _c(
                                "label",
                                {
                                  staticClass:
                                    "btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow",
                                  attrs: {
                                    "data-action": "change",
                                    "data-toggle": "tooltip",
                                    title: "",
                                    "data-original-title": "Change avatar"
                                  }
                                },
                                [
                                  _c("i", {
                                    staticClass: "fa fa-pen icon-sm text-muted"
                                  }),
                                  _c("input", {
                                    attrs: {
                                      type: "file",
                                      name: "profile_avatar",
                                      accept: ".png, .jpg, .jpeg"
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.onFileChange($event)
                                      }
                                    }
                                  }),
                                  _c("input", {
                                    attrs: {
                                      type: "hidden",
                                      name: "profile_avatar_remove"
                                    }
                                  })
                                ]
                              )
                            ]
                          ),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v("Tipos permitidos: png, jpg, jpeg.")
                          ])
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Estado del servicio")]),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mt-3",
                              attrs: { size: "lg" },
                              model: {
                                value: _vm.service.active,
                                callback: function($$v) {
                                  _vm.$set(_vm.service, "active", $$v)
                                },
                                expression: "service.active"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.service.active ? "Activo" : "Inactivo"
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "h3",
                        { staticClass: "mb-10 font-weight-bold text-dark" },
                        [_vm._v(" Demo ")]
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Tipo de modelo")]),
                          _c("b-form-select", {
                            staticClass:
                              "mb-3 form-control form-control-solid form-control-lg",
                            attrs: {
                              options: _vm.serviceTypes,
                              "value-field": "item",
                              "text-field": "name",
                              "input-id": "type-basic",
                              "disabled-field": "notEnabled"
                            },
                            model: {
                              value: _vm.service.type,
                              callback: function($$v) {
                                _vm.$set(_vm.service, "type", $$v)
                              },
                              expression: "service.type"
                            }
                          }),
                          !_vm.$v.service.type.required
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" Este campo es obligatorio ")
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Tipo de input")]),
                          _c("b-form-select", {
                            staticClass:
                              "mb-3 form-control form-control-solid form-control-lg",
                            attrs: {
                              options: _vm.serviceInputTypes,
                              "value-field": "item",
                              "text-field": "name",
                              "input-id": "input-type",
                              "disabled-field": "notEnabled"
                            },
                            model: {
                              value: _vm.service.inputType,
                              callback: function($$v) {
                                _vm.$set(_vm.service, "inputType", $$v)
                              },
                              expression: "service.inputType"
                            }
                          }),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              "Define el tipo de input a utilizar por el usuario en el demo del servicio"
                            )
                          ]),
                          !_vm.$v.service.inputType.required
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" Este campo es obligatorio ")
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          ref: "schema",
                          staticClass: "form-group",
                          attrs: { id: "demoschema" }
                        },
                        [
                          _vm._m(0),
                          _c("textarea", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.service.schema,
                                expression: "service.schema"
                              }
                            ],
                            staticClass:
                              "form-control form-control-solid form-control-lg",
                            domProps: { value: _vm.service.schema },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.service,
                                  "schema",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm.service.inputType == "url"
                            ? _c(
                                "span",
                                { staticClass: "form-text text-muted" },
                                [_vm._v('Ejemplo {"urls":[$textToAnalyze]}')]
                              )
                            : _vm.service.inputType == "file"
                            ? _c(
                                "span",
                                { staticClass: "form-text text-muted" },
                                [_vm._v('Ejemplo {"file":$textToAnalyze}')]
                              )
                            : _c(
                                "span",
                                { staticClass: "form-text text-muted" },
                                [_vm._v('Ejemplo {"texts":[$textToAnalyze]}')]
                              ),
                          !_vm.$v.service.schema.required
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" Este campo es obligatorio ")
                              ])
                            : _vm._e()
                        ]
                      ),
                      _vm.service.type == "ocr_gs" &&
                      _vm.service.inputType == "file"
                        ? _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [
                                _vm._v("Validar respuesta manualmente")
                              ]),
                              _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "mt-3",
                                  attrs: { size: "lg" },
                                  model: {
                                    value: _vm.service.validateRequest,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.service,
                                        "validateRequest",
                                        $$v
                                      )
                                    },
                                    expression: "service.validateRequest"
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.service.validateRequest ? "Si" : "No"
                                    )
                                  )
                                ]
                              ),
                              _c(
                                "span",
                                { staticClass: "form-text text-muted" },
                                [
                                  _vm._v(
                                    "Todas las peticiones pasarán primero a una lista de peticiones a verificar por un usuario validador."
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "h3",
                        { staticClass: "mb-10 font-weight-bold text-dark" },
                        [_vm._v(" Versionado ")]
                      ),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Versión")]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-group input-group-lg input-group-solid"
                          },
                          [
                            _vm._m(1),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.service.version,
                                  expression: "service.version"
                                }
                              ],
                              ref: "version",
                              staticClass:
                                "form-control form-control-lg form-control-solid",
                              attrs: {
                                type: "text",
                                name: "version",
                                placeholder: "Versión del servicio"
                              },
                              domProps: { value: _vm.service.version },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.service,
                                    "version",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _c("span", { staticClass: "form-text text-muted" }, [
                          _vm._v("La versión actual del modelo")
                        ])
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Fecha de creación")]),
                          _c("b-form-datepicker", {
                            staticClass:
                              "form-control form-control-solid form-control-lg",
                            attrs: {
                              id: "datepicker-creation-date",
                              "date-format-options": {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric"
                              },
                              "value-as-date": true,
                              locale: "es"
                            },
                            model: {
                              value: _vm.service.serviceCreationDate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.service,
                                  "serviceCreationDate",
                                  $$v
                                )
                              },
                              expression: "service.serviceCreationDate"
                            }
                          }),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v("Fecha en que el modelo se creó")
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Último despliegue")]),
                          _c("b-form-datepicker", {
                            staticClass:
                              "form-control form-control-solid form-control-lg",
                            attrs: {
                              id: "datepicker-last-deployment",
                              "date-format-options": {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric"
                              },
                              "value-as-date": true,
                              locale: "es"
                            },
                            model: {
                              value: _vm.service.serviceLastDeploy,
                              callback: function($$v) {
                                _vm.$set(_vm.service, "serviceLastDeploy", $$v)
                              },
                              expression: "service.serviceLastDeploy"
                            }
                          }),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              "Fecha en que el modelo se desplegó por última vez"
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Último entrenamiento")]),
                          _c("b-form-datepicker", {
                            staticClass:
                              "form-control form-control-solid form-control-lg",
                            attrs: {
                              id: "datepicker-last-training",
                              "date-format-options": {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric"
                              },
                              "value-as-date": true,
                              locale: "es"
                            },
                            model: {
                              value: _vm.service.serviceLastTraining,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.service,
                                  "serviceLastTraining",
                                  $$v
                                )
                              },
                              expression: "service.serviceLastTraining"
                            }
                          }),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              "Fecha en que el modelo se entrenó por última vez"
                            )
                          ])
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pb-5",
                      attrs: { "data-wizard-type": "step-content" }
                    },
                    [
                      _c(
                        "h4",
                        { staticClass: "mb-10 font-weight-bold text-dark" },
                        [_vm._v(" Integración de Servicio ")]
                      ),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Slug")]),
                        _c(
                          "div",
                          {
                            staticClass:
                              "input-group input-group-lg input-group-solid"
                          },
                          [
                            _vm._m(2),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.service.slug,
                                  expression: "service.slug"
                                }
                              ],
                              ref: "slug",
                              staticClass:
                                "form-control form-control-lg form-control-solid",
                              attrs: {
                                type: "text",
                                name: "slug",
                                placeholder: "Slug del servicio"
                              },
                              domProps: { value: _vm.service.slug },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.service,
                                    "slug",
                                    $event.target.value
                                  )
                                }
                              }
                            })
                          ]
                        ),
                        _c("span", { staticClass: "form-text text-muted" }, [
                          _vm._v(
                            "Lo usarán los usuarios para acceder al servicio (Ejemplo: /slug)"
                          )
                        ]),
                        !_vm.$v.service.slug.required
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(" Este campo es obligatorio ")
                            ])
                          : _vm._e(),
                        !_vm.$v.service.slug.minLength
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(
                                " Son requeridos por lo menos 5 caracteres. "
                              )
                            ])
                          : _vm._e(),
                        !_vm.$v.service.slug.notExists &&
                        !_vm.$v.service.slug.$pending
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(" El slug ya existe. ")
                            ])
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Costo")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.service.creditCost,
                              expression: "service.creditCost",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass:
                            "form-control form-control-solid form-control-lg",
                          attrs: {
                            type: "number",
                            min: "1",
                            name: "credit-cost",
                            placeholder: "Costo en creditos"
                          },
                          domProps: { value: _vm.service.creditCost },
                          on: {
                            keydown: _vm.filterKey,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.service,
                                "creditCost",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _c("span", { staticClass: "form-text text-muted" }, [
                          _vm._v("Se le descontarán los creditos")
                        ]),
                        !_vm.$v.service.creditCost.required
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(" Este campo es obligatorio ")
                            ])
                          : _vm._e(),
                        !_vm.$v.service.creditCost.minValue
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(
                                " El costo de creditos debe ser mayor a 0. "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Url de integración")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.service.consumerApiBaseUrl,
                              expression: "service.consumerApiBaseUrl"
                            }
                          ],
                          staticClass:
                            "form-control form-control-solid form-control-lg",
                          attrs: {
                            type: "text",
                            name: "url",
                            placeholder: "Url de integración de servicio"
                          },
                          domProps: { value: _vm.service.consumerApiBaseUrl },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.service,
                                "consumerApiBaseUrl",
                                $event.target.value
                              )
                            }
                          }
                        }),
                        _c("span", { staticClass: "form-text text-muted" }, [
                          _vm._v("Ingrese la url del servicio a integrar")
                        ]),
                        !_vm.$v.service.consumerApiBaseUrl.required
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(" Este campo es obligatorio ")
                            ])
                          : _vm._e(),
                        !_vm.$v.service.consumerApiBaseUrl.url
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(
                                " El valor ingresado no es una url válida "
                              )
                            ])
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: { label: "Metodos de acceso" },
                              model: {
                                value: _vm.consumerApiRestMethods,
                                callback: function($$v) {
                                  _vm.consumerApiRestMethods = $$v
                                },
                                expression: "consumerApiRestMethods"
                              }
                            },
                            [
                              _c("b-form-radio-group", {
                                attrs: {
                                  id: "checkbox-group-2",
                                  name: "rest-methods",
                                  options: ["GET", "POST"]
                                },
                                model: {
                                  value: _vm.consumerApiRestMethods,
                                  callback: function($$v) {
                                    _vm.consumerApiRestMethods = $$v
                                  },
                                  expression: "consumerApiRestMethods"
                                }
                              })
                            ],
                            1
                          ),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              "Ingrese los metodos aceptados por el servicio"
                            )
                          ]),
                          !_vm.$v.service.consumerApiRestMethods.required
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" Este campo es obligatorio ")
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Llamada asincrona")]),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mt-3",
                              attrs: { size: "lg" },
                              model: {
                                value: _vm.service.isAsyncCall,
                                callback: function($$v) {
                                  _vm.$set(_vm.service, "isAsyncCall", $$v)
                                },
                                expression: "service.isAsyncCall"
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.service.isAsyncCall
                                    ? "Asincrono"
                                    : "Sincrono"
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _vm.service.isAsyncCall
                        ? _c("div", { staticClass: "form-group" }, [
                            _c("label", [
                              _vm._v("Url de llamada asincrona (GET)")
                            ]),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.service.asyncApiBaseUrl,
                                  expression: "service.asyncApiBaseUrl"
                                }
                              ],
                              staticClass:
                                "form-control form-control-solid form-control-lg",
                              attrs: {
                                type: "text",
                                name: "url",
                                placeholder: "Url de llamada asincrona"
                              },
                              domProps: { value: _vm.service.asyncApiBaseUrl },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.service,
                                    "asyncApiBaseUrl",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _c(
                              "span",
                              { staticClass: "form-text text-muted" },
                              [
                                _vm._v(
                                  "Ingrese la url de llamada asincrona del servicio a integrar"
                                )
                              ]
                            )
                          ])
                        : _vm._e(),
                      _c("HeaderList", {
                        ref: "headerList",
                        attrs: {
                          "data-object": _vm.service.headers,
                          label: "Cabeceras Adicionales",
                          "help-text":
                            "Ingrese cabeceras adicionales que se mandarán al consumir el servicio"
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("p", [
                            _vm._v(" Idiomas aceptados por el servicio ")
                          ]),
                          _c("b-form-tags", {
                            staticClass:
                              "form-control form-control-solid form-control-lg",
                            attrs: {
                              placeholder: "Agrega un idioma y presiona enter",
                              "input-id": "service-languages",
                              "add-button-text": "Agregar",
                              "invalid-tag-text":
                                "El código de idioma debe contener dos caracteres y no puede ser duplicado",
                              "duplicate-tag-text":
                                "El código de idioma ya existe",
                              "tag-validator": _vm.validateServiceLanguages
                            },
                            on: {
                              input: function($event) {
                                _vm.service.languages[
                                  _vm.service.languages.length - 1
                                ] = _vm.service.languages[
                                  _vm.service.languages.length - 1
                                ].toUpperCase()
                              }
                            },
                            model: {
                              value: _vm.service.languages,
                              callback: function($$v) {
                                _vm.$set(_vm.service, "languages", $$v)
                              },
                              expression: "service.languages"
                            }
                          }),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              "Esto se muestra de manera informativa al usuario que desea probar el servicio. Ejemplo: ES"
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pb-5 row",
                      attrs: { "data-wizard-type": "step-content" }
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("h4", [_vm._v("Contenidos y Documentación")]),
                          _c("br"),
                          _c("h5", [_vm._v("Traducciones Existentes")]),
                          _c(
                            "div",
                            { staticClass: "list-group mt-5" },
                            [
                              _vm._l(_vm.translations, function(
                                translation,
                                i
                              ) {
                                return _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass:
                                      "list-group-item d-flex align-items-center"
                                  },
                                  [
                                    _c("div", { staticClass: "mr-auto" }, [
                                      _c("span", [
                                        _vm._v(
                                          " " +
                                            _vm._s(translation.localeCode) +
                                            " "
                                        )
                                      ])
                                    ]),
                                    _c("div", [
                                      translation.localeCode ==
                                      _vm.service.defaultLocale
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "mr-2 btn disabled btn-secondary",
                                              attrs: { disabled: "" }
                                            },
                                            [_vm._v("Predeterminado")]
                                          )
                                        : _c(
                                            "button",
                                            {
                                              staticClass:
                                                "mr-2 btn btn-outline-secondary",
                                              on: {
                                                click: function() {
                                                  _vm.service.defaultLocale =
                                                    translation.localeCode
                                                }
                                              }
                                            },
                                            [_vm._v("Predeterminar")]
                                          ),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-outline-primary mr-2",
                                          on: {
                                            click: function() {
                                              _vm.newTranslate = Object.assign(
                                                {},
                                                translation
                                              )
                                              _vm.editIndex = i
                                              if (
                                                translation.localeCode ==
                                                _vm.service.defaultLocale
                                              ) {
                                                _vm.editingDefaultLocale = true
                                              }
                                              _vm.showLocaleModal = true
                                            }
                                          }
                                        },
                                        [_vm._v("Editar")]
                                      ),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-outline-danger",
                                          on: {
                                            click: function() {
                                              _vm.deleteIndex = i
                                              if (
                                                translation.localeCode ==
                                                _vm.service.defaultLocale
                                              ) {
                                                _vm.editingDefaultLocale = true
                                              }
                                              _vm.$refs[
                                                "delete_confirm_modal"
                                              ].show()
                                            }
                                          }
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "far fa-trash-alt"
                                          })
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              }),
                              !_vm.$v.translations.minLength ||
                              !_vm.$v.translations.required
                                ? _c(
                                    "div",
                                    { staticClass: "error text-primary" },
                                    [
                                      _vm._v(
                                        " Debes agregar al menos una traducción. "
                                      )
                                    ]
                                  )
                                : _vm._e()
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "form-group row m-0 mt-2" },
                            [
                              _c("div", { staticClass: "mx-auto" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary form-control-solid form-control-md",
                                    on: {
                                      click: function($event) {
                                        return _vm.addTranslate($event)
                                      }
                                    }
                                  },
                                  [_vm._v(" Agregar Traducción ")]
                                )
                              ])
                            ]
                          ),
                          _c("EditServiceLocale", {
                            attrs: {
                              show: _vm.showLocaleModal,
                              "translation-data": _vm.newTranslate,
                              usedLocaleCodes: _vm.service.locales
                            },
                            on: {
                              "locale-edit-accept": _vm.onLocaleModalAccepted,
                              "locale-edit-cancel": _vm.onLocaleModalCancelled
                            }
                          }),
                          _c(
                            "b-modal",
                            {
                              ref: "delete_confirm_modal",
                              attrs: {
                                "hide-footer": "",
                                title:
                                  "Confirmar Borrado de Traducción " +
                                  (_vm.translations[_vm.deleteIndex]
                                    ? _vm.translations[_vm.deleteIndex]
                                        .localeCode
                                    : "")
                              }
                            },
                            [
                              _c("div", { staticClass: "d-block" }, [
                                _vm._v(
                                  " ¿Segur@ que deseas borrar la traducción " +
                                    _vm._s(
                                      _vm.translations[_vm.deleteIndex]
                                        ? _vm.translations[_vm.deleteIndex]
                                            .localeCode
                                        : ""
                                    ) +
                                    "? "
                                )
                              ]),
                              _c(
                                "div",
                                { staticClass: "d-flex justify-content-end" },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-3 mr-2",
                                      attrs: { variant: "outline-danger" },
                                      on: { click: _vm.removeTranslation }
                                    },
                                    [_vm._v("Borrar")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "mt-3",
                                      attrs: { variant: "outline-secondary" },
                                      on: {
                                        click: function($event) {
                                          return _vm.$refs[
                                            "delete_confirm_modal"
                                          ].hide()
                                        }
                                      }
                                    },
                                    [_vm._v("Cancelar")]
                                  )
                                ],
                                1
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pb-5",
                      attrs: { "data-wizard-type": "step-content" }
                    },
                    [
                      _c(
                        "h4",
                        { staticClass: "mb-10 font-weight-bold text-dark" },
                        [_vm._v(" Limitaciones de uso ")]
                      ),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [_vm._v("Elementos maximos por petición")]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.service.maximumRequestElements,
                              expression: "service.maximumRequestElements",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass:
                            "form-control form-control-solid form-control-lg",
                          attrs: {
                            type: "number",
                            min: "1",
                            name: "locaddress1"
                          },
                          domProps: {
                            value: _vm.service.maximumRequestElements
                          },
                          on: {
                            keydown: _vm.filterKey,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.service,
                                "maximumRequestElements",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _c("span", { staticClass: "form-text text-muted" }, [
                          _vm._v(
                            "Define el numero de elementos maximos por petición de usuario"
                          )
                        ]),
                        !_vm.$v.service.maximumRequestElements.required
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(" Este campo es obligatorio ")
                            ])
                          : _vm._e()
                      ]),
                      _c("div", { staticClass: "form-group" }, [
                        _c("label", [
                          _vm._v("Caracteres maximos por elemento")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.service.maximumElementCharacters,
                              expression: "service.maximumElementCharacters",
                              modifiers: { number: true }
                            }
                          ],
                          staticClass:
                            "form-control form-control-solid form-control-lg",
                          attrs: {
                            type: "number",
                            min: "1",
                            name: "locaddress1"
                          },
                          domProps: {
                            value: _vm.service.maximumElementCharacters
                          },
                          on: {
                            keydown: _vm.filterKey,
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.service,
                                "maximumElementCharacters",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function($event) {
                              return _vm.$forceUpdate()
                            }
                          }
                        }),
                        _c("span", { staticClass: "form-text text-muted" }, [
                          _vm._v(
                            "Define el maximo de caracteres por cada elemento de la petición"
                          )
                        ]),
                        !_vm.$v.service.maximumElementCharacters.required
                          ? _c("div", { staticClass: "error text-primary" }, [
                              _vm._v(" Este campo es obligatorio ")
                            ])
                          : _vm._e()
                      ]),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _vm._m(3),
                          _c("b-form-datepicker", {
                            staticClass:
                              "form-control form-control-solid form-control-lg",
                            attrs: {
                              id: "datepicker-init-date",
                              "date-format-options": {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric"
                              },
                              "value-as-date": true,
                              "reset-button": "",
                              locale: "es"
                            },
                            model: {
                              value: _vm.service.avaliableDateFrom,
                              callback: function($$v) {
                                _vm.$set(_vm.service, "avaliableDateFrom", $$v)
                              },
                              expression: "service.avaliableDateFrom"
                            }
                          }),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              "Fecha en que el servicio comenzará a estar disponible"
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _vm._m(4),
                          _c("b-form-datepicker", {
                            staticClass:
                              "form-control form-control-solid form-control-lg",
                            attrs: {
                              id: "datepicker-finish-date",
                              "date-format-options": {
                                year: "numeric",
                                month: "numeric",
                                day: "numeric"
                              },
                              "value-as-date": true,
                              "reset-button": "",
                              locale: "es"
                            },
                            model: {
                              value: _vm.service.avaliableDateTo,
                              callback: function($$v) {
                                _vm.$set(_vm.service, "avaliableDateTo", $$v)
                              },
                              expression: "service.avaliableDateTo"
                            }
                          }),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              "Fecha en que el servicio dejará de estar disponible"
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", [_vm._v("Visibilidad")]),
                          _c("b-form-select", {
                            staticClass:
                              "form-control form-control-solid form-control-lg",
                            attrs: {
                              options: _vm.visibilityOptions,
                              "value-field": "item",
                              "text-field": "name",
                              "input-id": "type-basic",
                              "disabled-field": "notEnabled"
                            },
                            model: {
                              value: _vm.service.visibility,
                              callback: function($$v) {
                                _vm.$set(_vm.service, "visibility", $$v)
                              },
                              expression: "service.visibility"
                            }
                          }),
                          _c("span", { staticClass: "form-text text-muted" }, [
                            _vm._v(
                              "Modo de visibilidad del servicio para los clientes"
                            )
                          ]),
                          !_vm.$v.service.visibility.required
                            ? _c("div", { staticClass: "error text-primary" }, [
                                _vm._v(" Este campo es obligatorio ")
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.service.visibility == "private"
                        ? _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("label", [_vm._v("Búsqueda de clientes")]),
                              _c(
                                "div",
                                {
                                  staticClass: "row",
                                  staticStyle: {
                                    "max-width": "calc(100% + 13px)"
                                  }
                                },
                                [
                                  _c("vue-bootstrap-typeahead", {
                                    staticClass: "col-9",
                                    attrs: {
                                      id: "tenantSearchBox",
                                      data: _vm.tenants.filter(function(
                                        tenant
                                      ) {
                                        return !_vm.tenantAccessGroup.some(
                                          function(t) {
                                            return t.id == tenant.id
                                          }
                                        )
                                      }),
                                      serializer: function(item) {
                                        return item.name
                                      }
                                    },
                                    on: {
                                      hit: function($event) {
                                        _vm.tenantSelected = $event
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return _vm.addClient($event)
                                      }
                                    },
                                    model: {
                                      value: _vm.tenantSearch,
                                      callback: function($$v) {
                                        _vm.tenantSearch = $$v
                                      },
                                      expression: "tenantSearch"
                                    }
                                  }),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-primary col-3 form-control-solid form-control-md",
                                      on: {
                                        click: function($event) {
                                          return _vm.addClient($event)
                                        }
                                      }
                                    },
                                    [_vm._v(" Agregar ")]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "form-text text-muted" },
                                [
                                  _vm._v(
                                    "Escribe al menos 2 letras del nombre del cliente"
                                  )
                                ]
                              ),
                              _c("br"),
                              _c("label", [_vm._v("Grupo de acceso")]),
                              _c(
                                "b-list-group",
                                _vm._l(_vm.tenantAccessGroup, function(
                                  tenant,
                                  i
                                ) {
                                  return _c(
                                    "b-list-group-item",
                                    {
                                      key: i,
                                      staticClass:
                                        "d-flex justify-content-between align-items-center"
                                    },
                                    [
                                      _vm._v(" " + _vm._s(tenant.name) + " "),
                                      _c(
                                        "b-badge",
                                        {
                                          staticClass: "m-0",
                                          staticStyle: { cursor: "pointer" },
                                          attrs: { variant: "primary" },
                                          on: {
                                            click: function($event) {
                                              return _vm.tenantAccessGroup.splice(
                                                i,
                                                1
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v("✖︎")]
                                      )
                                    ],
                                    1
                                  )
                                }),
                                1
                              ),
                              _c(
                                "span",
                                { staticClass: "form-text text-muted" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.tenantAccessGroup.length) +
                                      " Cliente" +
                                      _vm._s(
                                        _vm.tenantAccessGroup.length != 1
                                          ? "s"
                                          : ""
                                      ) +
                                      " con acceso al servicio actual"
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex justify-content-between border-top pt-10"
                    },
                    [
                      _vm._m(5),
                      _c("div", [
                        _c(
                          "button",
                          {
                            ref: "kt_submit_service",
                            staticClass: "btn btn-success btn-lg",
                            attrs: {
                              "data-wizard-type": "action-submit",
                              disabled: _vm.$v.service.$invalid
                            },
                            on: { click: _vm.submit }
                          },
                          [_vm._v(" Finalizar ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary btn-lg",
                            attrs: {
                              "data-wizard-type": "action-next",
                              disabled: !_vm.nextButtonValid
                            }
                          },
                          [_vm._v(" Siguiente ")]
                        )
                      ])
                    ]
                  )
                ])
              ])
            ]
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v(" La variable "),
      _c("b", [_vm._v("$textToAnalyze")]),
      _vm._v(
        " se sustituirá por el texto enviado por el usuario desde el demo del servicio "
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la" }, [_vm._v("v")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("span", { staticClass: "input-group-text" }, [
        _c("i", { staticClass: "la" }, [_vm._v("/")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Fecha de inicio "),
      _c("small", [_vm._v("(opcional)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _vm._v("Fecha de fin "),
      _c("small", [_vm._v("(opcional)")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mr-2" }, [
      _c(
        "button",
        {
          staticClass: "btn btn-light-primary btn-lg",
          attrs: { "data-wizard-type": "action-prev" }
        },
        [_vm._v(" Anterior ")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }