import { render, staticRenderFns } from "./NewService.vue?vue&type=template&id=281d634a&"
import script from "./NewService.vue?vue&type=script&lang=js&"
export * from "./NewService.vue?vue&type=script&lang=js&"
import style0 from "./NewService.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./NewService.vue?vue&type=style&index=1&v-if=service.id&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('281d634a')) {
      api.createRecord('281d634a', component.options)
    } else {
      api.reload('281d634a', component.options)
    }
    module.hot.accept("./NewService.vue?vue&type=template&id=281d634a&", function () {
      api.rerender('281d634a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/view/pages/services/NewService.vue"
export default component.exports